.logo2 {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.logoDesktopHeader {
height: 70px;
margin: 20px;
background: rgba(255, 255, 255, 0.8);
width: 150px;
}

.logoMobileHeader {
height: 70px;
background: rgba(255, 255, 255, 0.8);
width: 150px;
display: flex;
flex-direction: center;
justify-content: center;
align-items: c;
}

.site-layout .site-layout-background {
background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
background: #141414;
}

.headerDesktop {
padding: 0;
height: 'auto';
display: 'flex';
flex-direction: 'row';
justify-content: 'space-between';
}

.ouletNameCardDesktop {
width: 400;
height: 75;
margin-top: 20;
text-align: center;
}

.ouletNameCard {
height: 75px;
text-align: center;
}