.containerDesktop {
  padding: 30px;
  background: #ececec;
  display: flex;
  flex-direction: row;
}

.containerMobile {
  padding: 10px;
  background: #ececec;
}

.table {
  margin: 10px;
}

.cardC1R1 {
  width: 300px;
  margin: 10px;
}

.cardC1 {
  width: 300px;
  margin: 10px
}

.cardC2R1 {
  width: 400px;
  margin: 10px;
}

.cardC2 {
  width: 400px;
  margin: 10px;
}

.cardMob {
  /* width: 250px; */
  margin: 10px;
}

.para {
  margin: 0em;
}

.unAuthHeadMobile{
  display: flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center
}

.unAuthHeadDesktop{
  display: flex;
  flex-direction:row;
  justify-content:space-around;
  align-items:center
}

.readStatusInside{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.readStatusInsideRole{
  display: flex;
  flex-direction: row;
  align-items: center;
}
