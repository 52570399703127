.table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tablelogo {
    width: 25px;
}