.login {
  /* height: 300px; */
  width: 500px;
  margin: auto;
  background: rgba(253, 253, 253, 0.3);
  align-content: center;
  margin-top: 50px;
  border-radius: 100;
}

.loginMobile {
/* height: 300px; */
/* width: 500px; */
margin: auto;
background: rgba(253, 253, 253, 0.3);
align-content: center;
align-items: center;
margin-top: 50px;
border-radius: 100;
}

.submit {
  align-self: start;
}

.default {
padding: 20px;
}

.logoDesktop {
height: 260px;
}

.logoMobile {
width: 302px;
height: 170px;
}

.containerDiv {
padding: 30px;
background: #ececec;
display: flex;
justify-content: space-around;
}

.containerDivMobile {
padding: 30px;
background: #ececec;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
}